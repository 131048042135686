export const getDescription = (
  description: string,
  part: number,
  flavour?: boolean
): string | string[] => {
  const descArray = description.split("*-----*");
  if (flavour) {
    const fl = [];
    fl.push(descArray[3] || "");
    fl.push(descArray[4] || "");
    fl.push(descArray[6] || "");

    // fl[0].replaceAll("<[^>]*>", "");
    // fl[1].replaceAll("<[^>]*>", "");
    return fl;
  }
  return descArray[part] || "";
};
