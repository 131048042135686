import React from "react";
import * as s from "./SocialNetwork.module.scss";
import roots from "../../../images/roots.png";
import arrow from "../../../images/arrowReadMore.svg";
import { Link } from "gatsby";

const SocialNetwork = () => {
  return (
    <>
      <div className={s.imageWrapper}>
        <img src={roots} alt="roots" className={s.roots}></img>
        <div className={s.textWrapper}>
          <p className={s.firstP}>The original social network</p>
          <p className={s.secondP}>
            The mycelial network aka the underground root structure of
            mushrooms, connects ecosystems, trees and transmits nutrients
            through its mycelial microscopic fibers.
          </p>
          <Link to="/about-us" className={s.link}>
            Find out more <img src={arrow} alt="arrow"></img>
          </Link>
        </div>
        {/* <img src={black} alt="" className={s.image}></img> */}
      </div>
    </>
  );
};

export default SocialNetwork;
