import React from "react";
import * as s from "./MeetYourShrooms.module.scss";
import ShroomsLogo from "../../molecules/ShroomsLogo/ShroomsLogo";
import ShroomsCardPage from "../../molecules/ShroomsCardPage/ShroomsCardPage";
import SocialNetwork from "../../molecules/SocialNetwork/SocialNetwork";

const MeetYourShrooms = () => {
  return (
    <div className={s.container}>
      <ShroomsLogo />
      <ShroomsCardPage page="shrooms" />
      <SocialNetwork />
    </div>
  );
};

export { MeetYourShrooms };
