import React from "react";
import * as s from "./ShroomsLogo.module.scss";
import mushroom from "../../../images/Group 283.webp";
import classNames from "classnames";

const cn = classNames.bind(s);

const ShroomsLogo = ({ single }: { single?: string }) => {
  return (
    <div className={s.container}>
      <div className={s.imageWrapper}>
        <img
          src={mushroom}
          alt=""
          className={cn(s.img, { [s.single]: single === "single" })}
        ></img>
      </div>
      <div className={s.wrapper}>
        <p className={s.firstP}>
          MEET YOUR <span>MUSHROOMS</span>
        </p>
        <p className={s.secondP}>
          New to mushrooms? Allow us to make an introduction
        </p>
      </div>
    </div>
  );
};

export default ShroomsLogo;
