import lions from "../images/Group 299.webp";
import reishi from "../images/reishis.webp";
import maitake from "../images/maitakes.webp";
import cordy from "../images/cordys.webp";
import turkey from "../images/turkeys.webp";
import chaga from "../images/chagas.webp";
import reishihover from "../images/reishihover.png";
import reishih from "../images/reishishover.png";
import maitakehover from "../images/maitakehover.png";
import maitaksehover from "../images/maitakeshover.png";
import lmhover from "../images/LMhover.png";
import LMhover from "../images/LMshover.png";
import chagahover from "../images/chagahover.png";
import chagahovers from "../images/chagashover.png";
import tthover from "../images/TThover.png";
import ttshover from "../images/ttshover.png";
import cordyhover from "../images/cordyhover.png";
import cordyshover from "../images/cordyShover.png";

export const shroomCards = [
  {
    image: reishi,
    type: "reishi",
    title: "<b>Reishi</b>",
    description: "Relax and recalibrate.",
    hover: reishihover,
    hoversmall: reishih,
  },
  {
    image: maitake,
    type: "maitake",
    title: "<b>Maitake</b>",
    description: "Maintain healthy blood sugar and body balance.",
    hover: maitakehover,
    hoversmall: maitaksehover,
  },
  {
    image: cordy,
    type: "cordy",
    title: "<b>Cordyceps</b>",
    description: "Keep the body going and the endurance high.",
    hover: cordyhover,
    hoversmall: cordyshover,
  },
  {
    image: lions,
    type: "lionsmane",
    title: "lion's <b>mane</b>",
    description: "Feed and protect your brain.",
    hover: lmhover,
    hoversmall: LMhover,
  },

  {
    image: chaga,
    type: "chaga",
    title: "<b>Chaga</b>",
    description: "Protect your health with the King of Mushrooms.",
    hover: chagahover,
    hoversmall: chagahovers,
  },
  {
    image: turkey,
    type: "turkeytail",
    title: "Turkey <b>tail</b>",
    description: "Take care of your gut and boost your immune system.",
    hover: tthover,
    hoversmall: ttshover,
  },
];
