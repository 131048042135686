import React from "react";
import { MeetYourShrooms } from "../components/organisms/MeetYourShrooms/MeetYourShrooms";
import { PageTitle, SEO } from "../modules/seo";

const Meet = () => {
  return (
    <div>
      <SEO title={PageTitle.MeetYourShrooms} />
      <MeetYourShrooms />
    </div>
  );
};

export default Meet;
