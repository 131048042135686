import React from "react";
import { shroomCards } from "../../../constants/shroomCards";
import ShroomsCard from "../../atoms/ShroomsCard/ShroomsCard";
import * as s from "./ShroomsCardPage.module.scss";
import classNames from "classnames";

const cn = classNames.bind(s);

const ShroomsCardPage = ({ page }: { page: string }) => {
  return (
    <>
      <div
        className={cn(s.cardWrapper, {
          [s.productCardWrapper]: page === "product",
        })}
      >
        {shroomCards.map((card, index) => (
          <ShroomsCard
            key={index}
            image={card.image}
            title={card.title}
            description={card.description}
            page={page}
            hover={card?.hover}
            hoversmall={card?.hoversmall}
            type={card?.type}
          />
        ))}
      </div>
    </>
  );
};

export default ShroomsCardPage;
