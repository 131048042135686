/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */

import { navigate } from "gatsby";
import { getDescription } from "../../../helpers/descriptionHelper";
import React, { useEffect, useState } from "react";
import * as s from "./ShroomsCard.module.scss";
import classNames from "classnames";

const cn = classNames.bind(s);

interface IShroomsCard {
  image?: string;
  title: string;
  description?: string;
  page: string;
  hover?: string;
  hoversmall?: string;
  type?: string;
}

const ShroomsCard = ({
  image,
  title,
  description,
  page,
  hover,
  hoversmall,
  type,
}: IShroomsCard) => {
  const [newDescription, setNewDescription] = useState("");
  const [hoverImage, setHoverImage] = useState(false);
  const [zoom, setZoom] = useState(false);

  useEffect(() => {
    const desc = getDescription(description || "", 0);
    setNewDescription(desc as string);
  }, []);

  return (
    <div
      onMouseEnter={() => {
        if (page === "mushroom") {
          setHoverImage(false);
          setZoom(true);
        } else {
          setHoverImage(true);
          setZoom(false);
        }
      }}
      onMouseLeave={() => {
        setHoverImage(false);
        setZoom(false);
      }}
      className={
        page === "product"
          ? s.productContainer
          : page === "mushroom"
          ? s.mushroomContainer
          : s.container
      }
      onClick={() => {
        navigate(`/meet-your-shroom-single/#${type}`, {
          state: {
            component: { title },
          },
        });
      }}
      onContextMenu={() => {
        navigate("/meet-your-shroom-single", {
          state: { component: { title } },
          replace: false,
        });
      }}
    >
      <div
        className={cn(s.imageWrapper, {
          [s.imageMushroomWrapper]: page === "mushroom",
        })}
        style={
          hoverImage && page === "shrooms"
            ? { backgroundImage: `url(${hover})`, width: "100%" }
            : hoverImage && page === "product"
            ? { backgroundImage: `url(${hoversmall})`, width: "100%" }
            : page === "mushroom" && zoom
            ? { transform: "scale(1.05)" }
            : {}
        }
      >
        <img
          src={image}
          alt=""
          className={cn(s.img, { [s.image]: page === "product" })}
        ></img>

        <div className={s.wrapper}>
          <p
            className={s.firstP}
            dangerouslySetInnerHTML={{ __html: title }}
            style={hoverImage ? { color: "#ffffff" } : {}}
          />
          <p
            className={cn(s.secondP)}
            style={hoverImage ? { color: "#ffffff" } : {}}
          >
            {newDescription}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ShroomsCard;
