// extracted by mini-css-extract-plugin
export var container = "ShroomsCard-module--container--BENWl";
export var firstP = "ShroomsCard-module--first-p--CimHR";
export var image = "ShroomsCard-module--image--7zx7G";
export var imageMushroomWrapper = "ShroomsCard-module--image-mushroom-wrapper--rSOET";
export var imageWrapper = "ShroomsCard-module--image-wrapper--H67yX";
export var img = "ShroomsCard-module--img--V7odl";
export var mushroomContainer = "ShroomsCard-module--mushroom-container--gsek8";
export var productContainer = "ShroomsCard-module--product-container--OnOcA";
export var secondP = "ShroomsCard-module--second-p--lbzka";
export var thirdP = "ShroomsCard-module--third-p--dRJA+";
export var wrapper = "ShroomsCard-module--wrapper--7No4z";